import { throttle } from "lodash-es";
import { ref, reactive, computed, watch } from "#imports";
import { type AdsSearchSuggestionResponse } from "~/api/common/adsSearchSuggestion";
import {
  type SuggestionResultSection,
  type SuggestionResultRestaurant,
} from "~/api/common/searchSuggestion";
import { type RecommendationSchema } from "~/types/SearchSuggestion";

type RestaurantSearchSuggestion = SuggestionResultRestaurant & {
  isAds?: boolean;
};

export default function useSearchSuggestion() {
  const MIN_KEYWORD_LENGTH = 3;
  const THROTTLE_IN_SECOND = 1500;
  const keyword = ref("");
  const isLoading = ref(false);
  const isSuccess = ref(false);
  const isAnyResult = computed(() => {
    return (
      adsSugestions.value.length > 0 ||
      suggestions.restaurants.data.length > 0 ||
      suggestions.locations.data.length > 0 ||
      suggestions.cuisines.data.length > 0 ||
      suggestions.diningStyles.data.length > 0 ||
      suggestions.mrtRoutes.data.length > 0 ||
      suggestions.btsRoutes.data.length > 0 ||
      suggestions.facilities.data.length > 0 ||
      suggestions.shoppingMalls.data.length > 0
    );
  });
  const isShowSuggestion = ref(false);
  const suggestions = reactive({
    restaurants: {
      isShowAll: ref(false),
      data: ref<RestaurantSearchSuggestion[]>([]),
      name: "restaurant",
    },
    locations: {
      isShowAll: ref(false),
      data: ref<SuggestionResultSection[]>([]),
      name: "location",
    },
    cuisines: {
      isShowAll: ref(false),
      data: ref<SuggestionResultSection[]>([]),
      name: "cuisine",
    },
    diningStyles: {
      isShowAll: ref(false),
      data: ref<SuggestionResultSection[]>([]),
      name: "diningStyle",
    },
    mrtRoutes: {
      isShowAll: ref(false),
      data: ref<SuggestionResultSection[]>([]),
      name: "mrtRoute",
    },
    facilities: {
      isShowAll: ref(false),
      data: ref<SuggestionResultSection[]>([]),
      name: "facility",
    },
    shoppingMalls: {
      isShowAll: ref(false),
      data: ref<SuggestionResultSection[]>([]),
      name: "shoppingMall",
    },
    btsRoutes: {
      isShowAll: ref(false),
      data: ref<SuggestionResultSection[]>([]),
      name: "btsRoute",
    },
  });
  const adsSugestions = ref<AdsSearchSuggestionResponse["data"]["restaurants"]>(
    []
  );
  const allowSearch = computed(() => {
    return keyword.value.length >= MIN_KEYWORD_LENGTH;
  });
  const allowGetAds = computed(() => {
    return keyword.value.length === 0 && adsSugestions.value.length === 0;
  });
  const recommendationSuggestions = reactive({
    promotions: [],
    interestings: [],
    restaurants: [],
  }) as RecommendationSchema;

  function submitHandler(searchText?: string) {
    keyword.value = searchText || keyword.value;

    if (!allowSearch.value) {
      return;
    }
    getSuggestion(keyword.value);
  }

  const inputHandler = throttle(
    function (event) {
      keyword.value = event.target.value.trim();
    },
    THROTTLE_IN_SECOND,
    { leading: false }
  );

  async function getSuggestion(keyword: string) {
    isLoading.value = true;
    const { adsMapper } = await import("~/services/restaurant");
    const { getSearchSuggestion } = await import(
      "~/api/common/searchSuggestion"
    );
    toggleHiddenResult("cuisine");
    toggleHiddenResult("location");
    toggleHiddenResult("restaurant");
    toggleHiddenResult("mrtRoute");
    toggleHiddenResult("btsRoute");
    toggleHiddenResult("diningStyle");
    toggleHiddenResult("facility");
    toggleHiddenResult("shoppingMall");
    const { isSuccess: fetchSuccess, data } = await getSearchSuggestion(
      keyword
    );
    isLoading.value = false;
    if (fetchSuccess && data) {
      isSuccess.value = true;
      const {
        locations,
        cuisines,
        restaurants,
        diningstyles,
        btsroutes,
        mrtroutes,
        facilities,
        shoppingmalls,
      } = data;

      suggestions.cuisines.data = cuisines;
      suggestions.locations.data = locations;
      suggestions.restaurants.data = restaurants;
      suggestions.diningStyles.data = diningstyles;
      suggestions.btsRoutes.data = btsroutes;
      suggestions.mrtRoutes.data = mrtroutes;
      suggestions.facilities.data = facilities;
      suggestions.shoppingMalls.data = shoppingmalls;

      suggestions.cuisines.isShowAll = false;
      suggestions.locations.isShowAll = false;
      suggestions.restaurants.isShowAll = false;
      suggestions.diningStyles.isShowAll = false;
      suggestions.mrtRoutes.isShowAll = false;
      suggestions.btsRoutes.isShowAll = false;
      suggestions.facilities.isShowAll = false;
      suggestions.shoppingMalls.isShowAll = false;

      if (adsSugestions.value.length) {
        adsSugestions.value.forEach((adsData) => {
          adsMapper(adsData, restaurants, 10, 1);
        });
      }
      return;
    }
    isSuccess.value = false;
  }

  function toggleHiddenResult(param: string) {
    if (param === "restaurant") {
      suggestions.restaurants.isShowAll = !suggestions.restaurants.isShowAll;
    } else if (param === "cuisine") {
      suggestions.cuisines.isShowAll = !suggestions.cuisines.isShowAll;
    } else if (param === "location") {
      suggestions.locations.isShowAll = !suggestions.locations.isShowAll;
    } else if (param === "diningStyle") {
      suggestions.diningStyles.isShowAll = !suggestions.diningStyles.isShowAll;
    } else if (param === "mrtRoute") {
      suggestions.mrtRoutes.isShowAll = !suggestions.mrtRoutes.isShowAll;
    } else if (param === "facility") {
      suggestions.facilities.isShowAll = !suggestions.facilities.isShowAll;
    } else if (param === "shoppingMall") {
      suggestions.shoppingMalls.isShowAll =
        !suggestions.shoppingMalls.isShowAll;
    } else if (param === "btsRoute") {
      suggestions.btsRoutes.isShowAll = !suggestions.btsRoutes.isShowAll;
    }
  }

  function toggleHiddenSuggestion(param?: boolean) {
    if (typeof param === "boolean") {
      isShowSuggestion.value = param;
    } else {
      isShowSuggestion.value = !isShowSuggestion.value;
    }
  }

  function resetSearchResult() {
    suggestions.restaurants.data = [];
    suggestions.locations.data = [];
    suggestions.cuisines.data = [];
    suggestions.diningStyles.data = [];
    suggestions.btsRoutes.data = [];
    suggestions.mrtRoutes.data = [];
    suggestions.facilities.data = [];
    suggestions.shoppingMalls.data = [];
    adsSugestions.value = [];
    isLoading.value = false;
  }

  async function getSuggestionAds() {
    const { getAdsSearchSuggestion } = await import(
      "~/api/common/adsSearchSuggestion"
    );
    const { isSuccess, data } = await getAdsSearchSuggestion();
    if (isSuccess && data) {
      adsSugestions.value = data;
    }
  }

  async function focusHandler() {
    if (allowGetAds.value) {
      await getSuggestionAds();
    }
    if (!allowSearch.value) {
      return;
    }
    if (isAnyResult.value) {
      isShowSuggestion.value = true;
      return;
    }
    await getSuggestion(keyword.value);
  }

  watch(keyword, (newVal) => {
    if (allowSearch.value) {
      isShowSuggestion.value = true;
      getSuggestion(newVal);
    }
  });

  return {
    focusHandler,
    inputHandler,
    submitHandler,
    resetSearchResult,
    toggleHiddenSuggestion,
    toggleHiddenResult,
    isLoading,
    isAnyResult,
    isSuccess,
    keyword,
    suggestions,
    isShowSuggestion,
    recommendationSuggestions,
  };
}
